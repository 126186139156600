import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import HomeAboveTheFold from '../../components/HomeAboveTheFold';
import Quotation from '../../components/Quotation';
import BoxSVG from '../../assets/svgs/box-dimensions.svg';
import MapSVG from '../../assets/svgs/map.svg';

const initialHeaderInfos = {
  title: 'Cotação de Frete Online',
  subtitle: 'Faça sua cotação de frete online com as melhores transportadoras',
};

const VOLUMES_STEP = 2;
const RESULT_STEP = 3;

const Images = [BoxSVG, MapSVG];

const QuotationPage = ({ location }) => {
  const [imageIndex, setImage] = useState(0);
  const [headerInfos, dispatch] = useState(() => initialHeaderInfos);
  const stepInitialValues = { volumes: location.state };

  const handleStepChange = ({ stats: { activeStep } }) => {
    if (activeStep === RESULT_STEP) {
      setImage(1);
      dispatch({
        title: 'Já temos um frete ideal!',
        subtitle: 'Sua cotação de frete online retornou o seguinte resultado:',
      });
    } else {
      setImage(0);
      dispatch(initialHeaderInfos);
    }
  };

  const Image = Images[imageIndex];

  return (
    <Layout location={location}>
      <SEO
        title="Cotação de Frete Online - Central do Frete"
        description="Veja como fazer sua cotação de frete online muito mais rápida e descomplicada na Central do Frete."
      />
      <Container className="mt-5 pl-0 pr-0" style={{ paddingBottom: '170px' }}>
        <Row>
          <Col xs="12" lg="8">
            <HomeAboveTheFold
              title={headerInfos.title}
              subtitle={headerInfos.subtitle}
            >
              <Quotation
                initialStep={VOLUMES_STEP}
                stepInitialValues={stepInitialValues}
                onStepChange={handleStepChange}
              />
            </HomeAboveTheFold>
          </Col>
          <Col className="col d-none d-lg-block pt-5 mt-5">
            <Image className="img-fluid" alt="imagem do passo" />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

QuotationPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
};

QuotationPage.defaultProps = {
  location: {},
};

export default QuotationPage;
